import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import {
    addConsumerOrder,
    approveAssistanceOrder, associateAssistance,
    associateGroup,
    associateOrder,
    associateOrders,
    cancelAdminOrder,
    cancelConsumerOrder,
    changeOrderState,
    consumerOrderAccept,
    consumerOrderAcknowledge,
    consumerOrderCooking,
    consumerOrderDelaying,
    consumerOrderPickUp,
    consumerOrderReady,
    consumerOrderReject,
    consumerOrderRepack,
    consumerOrderSearch,
    courierReorder,
    createNewOrder,
    deleteAssistanceOrder,
    deleteOrder,
    deliveryAdminOrder,
    editOrder,
    exchangeOrders,
    fetchActiveOrders,
    fetchAdminOrderById,
    fetchAdminOrders,
    fetchBusinessInfo,
    fetchCancelerInfo,
    fetchConsumerInfo,
    fetchConsumerOrders,
    fetchCourierInfo,
    fetchCycle,
    fetchOrderMenu,
    fetchPendingPreOrders,
    fetchReceipts, getAdminOrderPengingCount,
    getAdvancedInfo,
    getCallCenterMenu,
    getDateOrders,
    getLastOrders,
    getMergedBusinessMenu, getMergedBusinessMenuById,
    getOrderPaymentIntent, getOrderSetupIntent,
    groupReorder,
    refundOrder,
    reloadOrder,
    reshipmentOrder,
    setCaptureRemainder,
    setCreatedOrders,
    setOrderDeliveredBy, stripeVerifyCardOrder,
    updateServiceNotes,
} from '../../api/order-requests';
import {
    actionAddNewOrders,
    actionAdminOrderGetOrderInfo, actionAdminOrderPendingCountUpdate,
    actionAdminOrdersLoading,
    actionAdminOrdersUpdate,
    actionAdminOrderUpdate,
    actionChangeItemsGroup,
    actionConsumerOrdersSearch,
    actionCycleReorder,
    actionGroupUpdateState,
    actionLoadOrdersFail,
    actionLoadOrdersSuccess,
    actionNewOrder,
    actionOrderMenuUpdate,
    actionPendingOrderCourierUpdate,
    actionPendingOrdersUpdate,
    actionPendingOrderUpdate,
    actionPendingPeorderDelete,
    actionRemoveOrder,
    actionSaveHistoryOrders,
    actionUpdateGroup,
    actionUpdateGroupOrdersMarkers,
    actionUpdateOrder,
    actionUpdateOrderData,
    actionUpdateOrderGroup,
    actionUpdateOrders,
    actionUpdateOrderServiceNotes,
    actionUpdateOrderState,
    ADMIN_ORDER_CANCEL,
    ADMIN_ORDER_CAPTURE,
    ADMIN_ORDER_DELIVERED,
    ADMIN_ORDER_GET,
    ADMIN_ORDER_GET_BY_ID,
    ADMIN_ORDER_GET_ORDER_INFO,
    ADMIN_ORDER_REFUND,
    ADMIN_ORDER_RESHIPMENT,
    APPROVE_ASSISTANCE_ORDER,
    ASSOCIATE_ORDERS,
    CHANGE_ORDER_INDEX,
    CHANGE_ORDER_STATE,
    CONSUMER_ORDER_ADD,
    CONSUMER_ORDER_CANCEL,
    CONSUMER_ORDER_SEARCH_SAGA,
    CONSUMER_PRE_ORDERS_GET, GET_ADMIN_ORDER_PENDING_COUNT,
    GET_ADVANCED_INFO,
    GET_MERGED_BUSINESS_MENU, GET_MERGED_BUSINESS_MENU_BY_ID,
    GET_ORDER_PAYMENT_INTENT,
    GET_ORDER_RECEIPTS, GET_ORDER_SETUP_INTENT,
    GET_ORDERS_PROVIDER,
    GET_PRODUCTS_BY_ID_BUSINESS,
    LOAD_HISTORY_ORDERS,
    MARKETPLACE_MESSAGE_DATA_GET,
    MARKETPLACE_MESSAGE_GET,
    MARKETPLACE_PAGES_GET,
    NEW_GROUP_REORDER,
    ORDER_ADMIN_INFO_GET,
    ORDER_ASSISTANCE_DELETE,
    ORDER_DELETE,
    ORDER_MENU_GET,
    ORDER_NOTE_EDIT,
    ORDERS_LOAD,
    PENDING_ORDER_CANCEL_CYCLE,
    PENDING_ORDER_DATA_GET,
    PENDING_ORDER_GET,
    PENDING_ORDER_SAVE,
    PENDING_ORDER_UN_ASSOCIATE_ORDER,
    PERFORM_ORDER_ASSOCIATING,
    PUSH_MESSAGE,
    PUSHER_CONSUMER_ORDER_ARRIVED,
    PUSHER_CYCLE_REORDER,
    PUSHER_GROUP_REORDER,
    PUSHER_NEW_ORDER,
    PUSHER_ORDER_STATE_UPDATE,
    PUSHER_UPDATE_ORDER,
    RELOAD_ORDER,
    SAVE_ORDER_TO_SERVER,
    SET_CREATED_ORDER,
    SET_ORDER_DELIVERED_BY,
    setAdminOrderSelectedOrderId, STRIPE_VERIFY_CARD_ORDER,
    UPDATE_GROUP_ORDERS_MARKERS,
} from '../reducers/order/order-actions';

import {
    actionLoadMarkers,
    actionMarkerUpdate,
    actionRemoveMarker,
    actionUpdateOrderMarker,
} from '../reducers/marker/marker-actions';

import {getParsedServerModel, objectKeysToUpperLowerCase} from '../../utils/objects-util';
import {createAction, createSaga, ErrorData} from '../../utils/sagaHelper';
import {
    dropPaymensZerro,
    extractOrders,
    getAPIType,
    getCycleData,
    getGroup,
    getTotalAmount,
    isDefaultGroup,
    isGroupHide,
    isNotDefaultOrderGroup,
    processHistoryOrders,
    removeAddressNulls,
    updateOrderData,
    updateOrdersMarkerType
} from '../../services/order';

import {
    ACCOUNT_ORDER_STATUS,
    ADMIN_ORDER_FILTER_TYPE as cancelerInfo,
    COURIER_STATE,
    CYCLE_STATE,
    DEFAULT_ORDERS_GROUPS,
    DELIVERY_STRATEGY,
    DIALOG_TYPE_CONFIRM,
    INTEGRATION_TYPE,
    MODEL_TYPE,
    ORDER_STATE,
    ORDER_TYPE, PAY_METHOD,
} from '../../utils/enums';
import {PUSHER_MANUAL_ASSOCIATE_UPDATE, PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST} from '../reducers/common-actions';
import {
    getAdminOrders,
    getAllOrders,
    getExternalCycles,
    getGroups,
    getHistoryOrders,
    getOrder,
    getOrders,
    getPendingOrders,
    getPickups,
    getRestaurant,
    getUserModel
} from '../selectors';
import {
    actionCheckUserGroup,
    actionCheckUsersColors,
    actionCourierToGroup,
    actionCourierUpdate,
    actionCycleUpdate,
    actionLoadUsersByIds
} from "../reducers/userModel/userModel-actions";
import {actionSetSectionItemsLoading} from '../reducers/sectionsWrapperData/sectionsWrapperData-actions';
import {prepareStartDate, setStartTime} from '../../utils/convertTime';
import User from '../../services/user';
import {getInitModel} from '../../services/initModels';
import AudioService from '../../services/audio';
import {getErrorMessage, requestResultIsOk} from '../../utils/request-util';
import {sortByDate} from '../../services/filter';
import {getReshipmentOrderData} from '../../services/restaurant';
import {
    currentUserIsDeliveryManager,
    currentUserIsDepotRole,
    getDistinctUsersIdsFromData,
    getUserByGroupId,
    getUserByOrder
} from '../../services/userModel';
import {fetchMarketplaceMessage, fetchPages, pushMessage} from '../../api/restaurant-requests';
import {setMarketplace, setMarketplacePages, setProductAction} from '../reducers/marketplace/marketplace-actions';
import {
    actionGetGiftCard,
    actionGetListPromotion,
    actionRestaurantUpdate,
    setOrdersProvider,
} from '../reducers/restaurant/restaurant-actions';
import {setPaymentIntent} from '../reducers/orderEdit/orderEdit-actions';
import {cancelCycle, fetchExternalProviders} from '../../api/user-requests';
import {
    actionDisbandOrderFromExternalCycle,
    actionExternalCycleDisband,
} from '../reducers/externalCycles/externalCycles-actions';
import {createOrderServerModel} from '../../models/createOrderServerModel';
import {getUsersByIdsSaga} from "./userModel-saga.js";
import {getHistoryLocationParams} from "../../utils/routesHelper.js";

export function* ordersSaga(action) {
    try {
        const { orders, cycles, assistanceOrders, activeOrders, restaurant, consumerOrders } = action;
        let users = yield select(getUserModel);
        yield put(
            actionLoadOrdersSuccess(
                extractOrders({ orders, cycles, assistanceOrders, users, restaurant, activeOrders, consumerOrders })
            )
        );

        if (cycles) {
            const groupStates = cycles.map(cycle => ({
                id: cycle.courierId,
                hide: isGroupHide(cycle.state),
            }));

            if (groupStates.length) yield put(actionGroupUpdateState(groupStates));
        }

        yield put(actionLoadMarkers());
        return true;
    } catch (e) {
        yield put(actionLoadOrdersFail('Error orders loading'));
    }
}

export function* cycleSaga(action) {
    try {
        const requestResult = yield call(fetchCycle);
        if (requestResult.status === 200) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionLoadOrdersSuccess(data));
        }
    } catch (e) {
        yield put(actionLoadOrdersFail('Error orders loading'));
    }
}

export function* newOrderSaga(action) {
    try {
        if (User.instance.skipObjectWithControlCenter(action.data.Order)) return;

        let newOrder = objectKeysToUpperLowerCase(action.data);
        if (newOrder.originalOrderId) {
            yield put(actionRemoveOrder([newOrder.originalOrderId]));
            yield put(actionRemoveMarker([newOrder.originalOrderId]));
        }

        yield put(actionNewOrder(newOrder));
        const orders = yield select(getOrders);
        const order = orders.find(f => f.id === newOrder.order.id);
        yield put(actionUpdateGroupOrdersMarkers({ groupId: order.groupId }));

        if (currentUserIsDepotRole()) {
            if (order.integrationType === INTEGRATION_TYPE.ordering) {
                AudioService.instance.playSound('newOrderCreated');
            }
        } else {
            if (currentUserIsDeliveryManager()) {
                AudioService.instance.playSound('newOrderCreated');
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function* updateOrderStateSaga(action) {
    try {
        let data = objectKeysToUpperLowerCase(action.data);
        if (User.instance.skipObjectWithControlCenter(data)) return;

        let groups = yield select(getGroups);
        let orders = yield select(getOrders);
        let pickups = yield select(getPickups);
        let users = yield select(getUserModel);
        const courier = getUserByGroupId(users, data.associatedCourierId);
        const canceledOrder = data.orderState === ORDER_STATE.canceled;
        const currentOrder = orders.find(f => f.id === data.orderId) || pickups.find(f => f.id === data.orderId);

        if (currentOrder) {
            const currentGroup = currentOrder && currentOrder.groupId;
            let removeOrder = false;
            if (courier) {
                removeOrder = canceledOrder && courier.cycle.state === CYCLE_STATE.started;
            } else if (canceledOrder) {
                removeOrder = true;
            } else if (currentGroup === DEFAULT_ORDERS_GROUPS.takeawayOrders) {
                removeOrder = data.orderState === ORDER_STATE.delivered;
            }

            if (removeOrder) {
                yield put(actionRemoveMarker([data.orderId]));
                yield put(actionRemoveOrder([data.orderId]));
            } else {
                const group = groups.find(f => f.id === currentOrder.groupId);

                if (group) {
                    let groupOrders = (orders || [])
                        .filter(f => f.groupId === currentOrder.groupId)
                        .concat((pickups || []).filter(f => f.groupId === currentOrder.groupId))
                        ?.sort((a, b) => a.index - b.index);
                    groupOrders = groupOrders.map(m => (m.id === data.orderId ? { ...m, state: data.orderState } : m));

                    if (courier) {
                        updateOrdersMarkerType(group, groupOrders);
                    }
                    const groupOrder = groupOrders.find(f => f.id === data.orderId);
                    yield put(actionUpdateOrderState(groupOrder));

                    // users = yield select(getUserModel);
                    orders = yield select(getOrders);
                    const order = orders.find(f => f.id === data.orderId);
                    if (order && order.groupId) {
                        yield put(actionUpdateGroupOrdersMarkers({ groupId: order.groupId }));
                    }
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function* updateOrderDataSaga(action) {
    try {
        let data = objectKeysToUpperLowerCase(action.data);
        yield put(actionUpdateOrder(data));

        let orders = yield select(getOrders);
        let order = orders.find(f => f.id === data.order.id);
        if (order && order.groupId) {
            yield put(actionUpdateGroupOrdersMarkers({ groupId: order.groupId }));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* reorderOrdersSaga(action) {
    try {
        let data = objectKeysToUpperLowerCase(action.data);
        if (data) {
            yield put(actionCheckUserGroup({ userId: data.courierId }));
            yield put(actionCycleReorder({ ...data, groupId: data.courierId }));

            const users = yield select(getUserModel);
            const orders = yield select(getAllOrders);
            const user = users.find(f => f.userId === data.courierId);

            const cycle = user.cycle || {};
            const notIncludeKeys = ['courierId', 'orderIds', 'pickUps'];
            Object.keys(data)
                .filter(key => !notIncludeKeys.includes(key))
                .forEach(key => {
                    cycle[key] = data[key];
                });

            if (!data.orders && data.orderIds.length) {
                cycle.orders = data.orderIds.map((id, index) => {
                    const order = cycle?.orders?.find(f => f.id === id) || orders.find(f => f.id === id) || {};
                    return { ...order, index, groupId: data.courierId };
                });
            }

            yield put(actionCycleUpdate({ courierId: data.courierId, data: cycle }));

            if ((data.orderIds || []).length) {
                yield put(actionUpdateGroupOrdersMarkers({ groupId: data.courierId }));
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function* manualAssociateUpdateSaga(action) {
    try {
        const data = objectKeysToUpperLowerCase(action.data);
        const orders = yield select(getOrders);
        const groups = yield select(getGroups);
        const order = orders.find(f => f.id === data.orderId);

        if (!order) return;

        let group = groups.find(f => f.id === data.courierId);

        if (!group) {
            const users = yield select(getUserModel);
            const user = users.find(f => f.userId === data.courierId);
            if (user) {
                group = getGroup(DEFAULT_ORDERS_GROUPS.courierGroup, user);
            }
        }

        if (group) {
            yield put(actionUpdateOrderGroup({ orderId: data.orderId, group }));
        }

        yield put(actionUpdateGroupOrdersMarkers({ groupId: data.courierId }));
    } catch (error) {
        console.log(error);
    }
}

export function* associateOrderSaga(action) {
    yield* createSaga(function*() {
        const { associateMode, orderId, courierId, group } = action.data;
        if (associateMode) {
            const groups = yield select(getGroups);
            const allOrders = yield select(getAllOrders);
            const pendingOrders = yield select(getPendingOrders);
            let orders = allOrders.concat(pendingOrders);

            if (orderId && courierId) {
                const restaurant = yield select(getRestaurant);
                const group = groups.find(g => g.id === courierId)
                const newOrderInGroup = orders.find(o => o.id === orderId);
                if(!newOrderInGroup.isAssistOrder && newOrderInGroup && group && group.controlCenterId && (newOrderInGroup.controlCenterId || newOrderInGroup.control_center_id) !== group.controlCenterId) {
                    return new ErrorData('Error');
                }
                const request = newOrderInGroup.isAssistOrder ? associateAssistance : associateOrder
                let requestResult = yield call(request, {
                    orderId,
                    courierId,
                    controlCenterId: restaurant.selectedControlCenter,
                });
                if (requestResultIsOk(requestResult)) {
                    yield put(actionPendingOrdersUpdate({ activeOrders: requestResult.data.Orders }));
                    yield put(actionPendingOrderCourierUpdate({ orderId, courierId }));

                    const cycle = objectKeysToUpperLowerCase(requestResult.data);
                    if (cycle) {
                        yield put(actionCourierUpdate({ courierId, cycle }));
                        if (cycle.orders) {
                            const users = yield select(getUserModel);
                            const cycleData = getCycleData(cycle, users, groups);

                            yield put(
                                actionRemoveOrder(
                                    orders
                                        .filter(f => f.groupId === courierId && f.type === ORDER_TYPE.pickUp)
                                        .map(m => m.id)
                                )
                            );
                            yield put(actionUpdateGroup(cycleData.group));
                            yield put(actionUpdateOrders(cycleData.orders));
                        }

                        yield all([
                            put(actionUpdateGroupOrdersMarkers({ groupId: courierId })),
                            put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted })),
                        ]);
                    } else {
                        //Fix for update order control center and remove order marker if control center changed.
                        const restaurant = yield select(getRestaurant);
                        orders = yield select(getOrders);
                        const order = orders.find(f => f.id === orderId);
                        const controlCenter = (restaurant.controlCenters || []).find(
                            f => f.controlCenterId === courierId
                        );

                        if (controlCenter && order.controlCenterId !== controlCenter.controlCenterId) {
                            yield put(
                                actionUpdateOrderData({ ...order, controlCenterId: controlCenter.controlCenterId })
                            );
                            if (
                                restaurant.selectedControlCenter &&
                                restaurant.selectedControlCenter !== controlCenter.controlCenterId
                            ) {
                                yield put(actionRemoveMarker([orderId]));
                            }
                        }

                        if (controlCenter) {
                            yield put(actionMarkerUpdate({ id: orderId, controlCenterId: courierId }));
                        }

                        yield put(actionUpdateGroupOrdersMarkers({ groupId: courierId }));
                        yield put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted }));

                        if (currentUserIsDepotRole()) {
                            const pendingOrders = yield select(getPendingOrders);
                            const pendingOrder = pendingOrders.find(f => f.id === orderId);
                            const updatedOrder = (cycle.orders || []).find(f => f.id === orderId) || {};

                            if (pendingOrder) {
                                yield put(
                                    actionPendingOrderUpdate({
                                        ...pendingOrder,
                                        ...updatedOrder,
                                        isExternalGroup: false,
                                        isCourierGroup: true,
                                        groupId: courierId,
                                        courierId: courierId,
                                        courier_id: courierId,
                                    })
                                );
                            }
                        }
                    }
                } else {
                    return new ErrorData(getErrorMessage(requestResult));
                }
            } else if (group) {
                let requestResult = group.isCourierGroup
                    ? yield call(exchangeOrders, group.id, courierId)
                    : yield call(associateGroup, group.id, courierId);
                if (requestResult.status === 200) {
                    const data = objectKeysToUpperLowerCase(requestResult.data);
                    const destinationGroup = groups.find(f => f.id === courierId);
                    const itemsIds = orders.filter(f => f.groupId === group.id).map(m => m.id);

                    yield put(actionChangeItemsGroup({ destinationGroup, itemsIds }));

                    //Fix for change group color and update group
                    yield put(actionCourierUpdate({ ...data, color: group.color }));
                    yield put(actionUpdateGroup({ ...group, color: destinationGroup.color }));
                    yield put(actionUpdateGroup({ ...data, ...destinationGroup, color: group.color }));

                    yield put(actionUpdateGroupOrdersMarkers({ groupId: courierId }));
                    yield put(actionUpdateGroupOrdersMarkers({ groupId: group.id }));
                } else {
                    return new ErrorData(getErrorMessage(requestResult));
                }
            }
        } else {
            //TODO: notassociate mode functional
        }
    }, action.type);
}

export function* changeOrderStateSaga(action) {
    yield* createSaga(function*() {
        const order = action.data.order;
        const state = action.data.state;
        const orderType = getAPIType(order);
        const requestResult = yield call(changeOrderState, order.id, state, orderType);

        if (requestResultIsOk(requestResult)) {
            if (state === ORDER_STATE.delivered && orderType === DEFAULT_ORDERS_GROUPS.takeaways) {
                yield put(actionRemoveOrder([order.id]));
                yield put(actionRemoveMarker([order.id]));
            } else {
                yield put(actionUpdateOrderData({ ...order, state, readinessTime: requestResult.data.ReadinessTime }));
            }

            yield put(actionUpdateGroupOrdersMarkers({ groupId: order.groupId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* updateOrderMarkersSaga(action) {
    const groupId = action.data.groupId;

    try {
        const groups = yield select(getGroups);
        const users = yield select(getUserModel);
        const orders = yield select(getOrders);
        const restaurant = yield select(getRestaurant);
        const depots = restaurant.depots || [];
        const group = groups.find(f => f.id === groupId);
        const groupOrders = orders.filter(f => f.groupId === groupId);
        const user = getUserByGroupId(users, groupId);

        yield put(actionUpdateOrderMarker({ group, groupOrders, user, depots }));
    } catch (error) {
        console.log(error);
    }
}

export function* reorderSaga(action) {
    yield* createSaga(function*() {
        const { groupId } = (action.data || {}).order || {};

        const groups = yield select(getGroups);
        const group = groupId && groups.find(f => f.id === groupId);

        if (group) {
            let requestResult;

            if (group.isCourierGroup) {
                yield put(actionCheckUserGroup({ userId: groupId }));
                requestResult = yield call(courierReorder, action.data.order.id, group.id, action.data.newIndex);
            } else {
                requestResult = yield call(groupReorder, {
                    OrderId: action.data.order.id,
                    TargetGroupId: group.id,
                    NewGroup: false,
                    Index: action.data.newIndex,
                });
            }

            if (requestResultIsOk(requestResult)) {
                const orders = yield select(getOrders);
                const pickUps = yield select(getPickups);
                let groupOrders = orders
                    .filter(order => order.groupId === group.id)
                    .concat(pickUps.filter(order => order.groupId === group.id))
                    ?.sort((a, b) => a.index - b.index);
                const order = groupOrders.find(order => order.id === action.data.order.id);
                if (order.index !== action.data.newIndex) {
                    groupOrders.splice(order.index, 1);
                    groupOrders.splice(action.data.newIndex, 0, order);
                }

                yield put(actionCycleReorder({ orderIds: groupOrders.map(m => m.id), groupId: group.id }));
                yield put(actionUpdateGroupOrdersMarkers({ groupId: group.id }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData("Can't find this group");
        }
    }, action.type);
}

export function* manualUnassociateUpdateListSaga(action) {
    const data = objectKeysToUpperLowerCase(action.data);
    if (User.instance.skipObjectWithControlCenter(data)) return;

    let orders = yield select(getOrders);
    let groups = yield select(getGroups);
    const orderReducerState = yield select(getOrder);

    const ordersIds = orders.map(order => order.id);
    if ((data.ordersId || []).some(orderId => !ordersIds.includes(orderId))) {
        const requestResult = yield call(fetchActiveOrders);
        if (requestResultIsOk(requestResult, true)) {
            const unsortedGroup = requestResult.data.Groups.find(g => g.Id === DEFAULT_ORDERS_GROUPS.Unsorted) || {};
            const unsortedGroupOrders = objectKeysToUpperLowerCase(unsortedGroup.Orders || []);
            const newOrders = data.ordersId.reduce((accumulator, orderId) => {
                if (!ordersIds.includes(orderId)) {
                    const newOrder = unsortedGroupOrders.find(order => order.id === orderId);
                    if (newOrder) {
                        accumulator.push(
                            updateOrderData(newOrder, false, DEFAULT_ORDERS_GROUPS.Unsorted, orderReducerState)
                        );
                    }
                }

                return accumulator;
            }, []);
            yield put(actionAddNewOrders(newOrders));
        }
    }

    const currentGroup = groups.find(f => f.id === data.id);
    if (currentGroup) {
        const currentGroupOrders = orders.filter(order => data.ordersId.includes(order.id));

        if (!currentGroup.isCourierGroup && isNotDefaultOrderGroup(data.id)) {
            const restaurant = yield select(getRestaurant);
            const depots = restaurant.depots || [];
            yield put(
                actionUpdateOrders(
                    currentGroupOrders.map(order => ({ ...order, groupId: DEFAULT_ORDERS_GROUPS.Unsorted }))
                )
            );
            yield put(actionUpdateOrderMarker({ currentGroup, currentGroupOrders, user: null, depots }));
        } else {
            if (currentGroup.isCourierGroup) {
                yield put(
                    actionUpdateOrders(
                        currentGroupOrders.map(order => ({ ...order, groupId: DEFAULT_ORDERS_GROUPS.Unsorted }))
                    )
                );
            }
        }
    }

    //Remove order from cycle list
    if (isNotDefaultOrderGroup(data.id) && (data.ordersId || []).length) {
        const users = yield select(getUserModel);
        const user = users.find(f => f.userId === data.id);

        if ((user || {}).cycle) {
            yield put(
                actionCycleUpdate({
                    courierId: data.id,
                    data: {
                        ...(user || {}).cycle,
                        orders: ((user || {}).cycle.orders || []).filter(f => !data.ordersId.includes(f.id)),
                    },
                })
            );
        }
    }

    groups = yield select(getGroups);
    const group = groups.find(f => f.id === DEFAULT_ORDERS_GROUPS.Unsorted);

    try {
        orders = yield select(getOrders);
        const restaurant = yield select(getRestaurant);
        const depots = restaurant.depots || [];
        const groupOrders = orders.filter(f => f.groupId === group.id);

        yield put(actionUpdateOrderMarker({ group, groupOrders, user: undefined, depots }));
    } catch (error) {
        console.log(error);
    }
}

export function* associateOrdersSaga(action) {
    yield* createSaga(function*() {
        const { orders, courierId, orderId } = action.data;
        const requestResult = yield call(associateOrders, courierId, orders);

        if (requestResult.status === 200) {
            if (requestResult.data) {
                const cycle = objectKeysToUpperLowerCase(requestResult.data);
                yield put(actionCourierUpdate({ courierId, cycle }));

                if(requestResult.data.Orders) {
                    yield put(actionPendingOrdersUpdate({ activeOrders: requestResult.data.Orders }));
                    yield put(actionPendingOrderCourierUpdate({ orderId, courierId }));
                }


                if (cycle.orders) {
                    yield put(
                        actionUpdateOrders(
                            cycle.orders.map((order, index) => ({ ...order, groupId: courierId, index }))
                        )
                    );
                }
            }

            yield put(actionUpdateGroupOrdersMarkers({ groupId: courierId }));
            yield put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* deleteOrderSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteOrder, action.data.order.id, action.data.token);
        if (requestResult.status === 200) {
            yield put(
                actionUpdateOrderData({
                    ...action.data.order,
                    state: ORDER_STATE.canceled,
                })
            );

            const users = yield select(getUserModel);
            const user = getUserByOrder(users, action.data.order);
            if (!user || user.cycle.state === CYCLE_STATE.started) {
                yield put(actionRemoveOrder([action.data.order.id]));
                yield put(actionRemoveMarker([action.data.order.id]));
            }
        } else {
            return new ErrorData('Error delete order');
        }
    }, action.type);
}

export function* deleteAssistanceOrderSaga(action) {
    yield* createSaga(function*() {
        const { order, token } = action.data;
        const requestResult = yield call(deleteAssistanceOrder, order.id, token);
        if (requestResult.status === 200) {
            const users = yield select(getUserModel);
            const user = users.find(f => f.userId === order.groupId);
            if (!user || user.cycle.state === CYCLE_STATE.started) {
                yield put(actionRemoveOrder([order.id]));
                yield put(actionRemoveMarker([order.id]));
            }
        } else {
            return new ErrorData('Error delete assistance order');
        }
    }, action.type);
}

function* saveOrderToServerSaga(action) {
    yield* createSaga(function*() {
        let order = {
            ...action.data,
            address: removeAddressNulls(action.data.address),
            payments: dropPaymensZerro(action.data.payments),
            amount: getTotalAmount(action.data.payments),
        };

        if (order.userName) {
            let arr = order.userName.trim().split(' ');
            order.lastName = arr.pop();
            order.firstName = arr.join(' ');
        }
        const serverModel = getParsedServerModel(order);
        const requestResult = yield call(order.id ? editOrder : createNewOrder, serverModel, order.type);

        if (requestResult.status === 200) {
            let data = objectKeysToUpperLowerCase(requestResult.data);
            let groupId = DEFAULT_ORDERS_GROUPS.Unsorted;

            if (data.order) {
                if (order.id) {
                    if (order.groupId && !order.type && !isDefaultGroup(order.groupId)) {
                        groupId = order.groupId;
                    }

                    yield put(
                        actionUpdateOrder({
                            ...data,
                            order: { ...data.order, groupId: data.associatedCourierId || data.groupId || groupId },
                        })
                    );
                } else {
                    AudioService.instance.playSound("newOrderCreated");
                    yield put(actionNewOrder(data));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult, 'Error save order'));
        }
    }, action.type);
}

function* approveAssistanceOrderSaga(action) {
    yield* createSaga(function*() {
        const order = {
            ...action.data,
            payments: dropPaymensZerro(action.data.payments),
            amount: getTotalAmount(action.data.payments),
        };
        const serverModel = getInitModel(MODEL_TYPE.assistanceServerModel, order);
        if (!order.type) {
            if (order.address) {
                serverModel.ParsedOrder.Address = getInitModel(MODEL_TYPE.addressServerModel, order.address);
            }
            if (order.location) {
                serverModel.Location = getInitModel(MODEL_TYPE.locationServerModel, order.location);
            }
        }

        const requestResult = yield call(approveAssistanceOrder, serverModel);
        if (requestResult.status === 200) {
            let data = objectKeysToUpperLowerCase(requestResult.data);
            let groupId = null;
            const newOrder = data.order;
            if (newOrder.id) {
                const groups = yield select(getGroups);
                const parent = groups.find(f => f.id === newOrder.groupId);
                if (parent && !newOrder.type && !isDefaultGroup(parent)) groupId = parent.id;
                yield put(
                    actionUpdateOrder({
                        ...data,
                        order: {
                            ...newOrder,
                            groupId: data.associatedCourierId || data.groupId || groupId,
                        },
                    })
                );
            } else {
                yield put(actionNewOrder(data));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult, 'Error save order'));
        }
    }, action.type);
}

function* getReceiptsSaga(action) {
    yield* createSaga(function*() {
        const ids = action.data.receiptIds;
        const apiCalls = ids.map(id => call(fetchReceipts, id));
        const apiCallResults = yield all(apiCalls);
        const results = apiCallResults.reduce((accumulator, result) => {
            if (result && result.status === 200 && result.data) {
                accumulator.push(result.data);
            }
            return accumulator;
        }, []);

        return results;
    }, action.type);
}

function* getAdvancedInfoSaga(action) {
    yield* createSaga(function*() {
        const ids = action.data.receiptIds;
        const apiCalls = ids.map(id => call(getAdvancedInfo, id));
        const apiCallResults = yield all(apiCalls);
        return apiCallResults.reduce((accumulator, result) => {
            if (result && result.status === 200 && result.data) {
                accumulator.push(result.data);
            }
            return accumulator;
        }, []);

    }, action.type);
}

function* loadHistoryOrdersSaga(action) {
    yield* createSaga(function*() {
        let { ordersFilterModel, activeOrders, historyOrders, clearHistory, hours, date } = action.data;

        if (isNaN(hours) && ordersFilterModel) {
            hours = ordersFilterModel.options.historyTime.selected;
        }

        if (date === undefined && ordersFilterModel && ordersFilterModel.options.date.selected) {
            date = prepareStartDate(setStartTime(ordersFilterModel.options.date.selected));
        }

        if(!date) {
            date = prepareStartDate(setStartTime(new Date()));
        }

        yield put(actionSetSectionItemsLoading(true));
        if (date) {
            const response = yield call(getDateOrders, date, ordersFilterModel.options.territories?.selected);
            if (response.status === 200) {
                const data = processHistoryOrders(activeOrders, historyOrders, clearHistory, response);
                const usersIds = yield select(state => state.userModel.data.map(u => u.userId));
                const newUserIds = [...new Set(response.data.map(order => order.DeliveryBy).filter(Boolean))];
                const uniqueIds = newUserIds.filter(id => !usersIds.includes(id));
                if(uniqueIds.length) {
                    yield getUsersByIdsSaga({ data: { ids: uniqueIds } });
                }

                yield put(actionSaveHistoryOrders(data));
                yield put(actionCheckUsersColors(getDistinctUsersIdsFromData(data)));
        yield put(actionSetSectionItemsLoading(false));
                return data;
            } else {
                yield put(actionSetSectionItemsLoading(false));
                return new ErrorData(response.Error);
            }
        }

        const response = yield call(getLastOrders, hours);
        if (response.status === 200) {
            const data = processHistoryOrders(activeOrders, historyOrders, clearHistory, response) || [];

            yield put(actionSetSectionItemsLoading(false));
            yield put(actionSaveHistoryOrders(data));
      yield put(actionCheckUsersColors(getDistinctUsersIdsFromData(data)));
            return data;
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(response.Error);
        }
    }, action.type);
}

function* getOrdersProviderSaga(action) {
    yield createSaga(function*() {
        const resultRequest = yield call(fetchExternalProviders);

        if (resultRequest && resultRequest.data) {
            const data = objectKeysToUpperLowerCase(resultRequest.data);
            yield put(setOrdersProvider(data));
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* reloadOrderSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(reloadOrder, action.data);
        if (requestResult.status === 200 && requestResult.data) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data.order && data.order.id) {
                const orders = yield select(getOrders);
                if (orders.find(order => order.id === data.order.id)) {
                    yield put(actionUpdateOrder({ order: data.order, preorder: data.isPreorder }));
                } else {
                    yield put(actionNewOrder({ order: data.order, preorder: data.isPreorder }));
                }
                return data;
            }
        } else {
            return new ErrorData('Error reload order');
        }
    }, action.type);
}

function* setOrderDeliveredBySaga(action) {
    yield* createSaga(function*() {
        const { orderId, courierId, filterOptions } = action.data;
        const requestResult = yield call(setOrderDeliveredBy, orderId, courierId);
        if (requestResult.status === 200) {
            const orders = yield select(getOrders);
            const historyOrders = yield select(getHistoryOrders);

            yield put(actionSetSectionItemsLoading(true));
            yield put(
                createAction(LOAD_HISTORY_ORDERS, {
                    ordersFilterModel: filterOptions,
                    activeOrders: orders,
                    historyOrders,
                    clearHistory: true,
                    hours: undefined,
                    date: undefined,
                })
            );
            yield put(actionSetSectionItemsLoading(false));
        }
    }, action.type);
}

function* pendingOrderSaveSaga(action) {
    yield* createSaga(function*() {
        const { id, item, index, forPreorders } = action.data;
        if (item.type && index >= 0) {
            let requestResult = null;

            if (item.type === DIALOG_TYPE_CONFIRM.accept) {
                requestResult = yield call(consumerOrderAccept, id, item.items[index].key);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.acknowledge) {
                requestResult = yield call(consumerOrderAcknowledge, id);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.reject) {
                requestResult = yield call(consumerOrderReject, id, item.items[index].value);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.delay) {
                requestResult = yield call(consumerOrderDelaying, id, item.items[index].key);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.cooking) {
                requestResult = yield call(consumerOrderCooking, id, item.items[index].key);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.ready) {
                requestResult = yield call(consumerOrderReady, id);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.delivery) {
                requestResult = yield call(consumerOrderPickUp, id);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.delivered) {
                if (!index) return;
                requestResult = yield call(changeOrderState, id, ORDER_STATE.delivered);
            }

            if (item.type === DIALOG_TYPE_CONFIRM.kitchen) {
                requestResult = yield call(consumerOrderRepack, id);
            }

            if (requestResultIsOk(requestResult)) {
                const data = objectKeysToUpperLowerCase(requestResult.data);
                if (data) {
                    if (forPreorders) {
                        yield put(actionPendingPeorderDelete(data));
                    } else {
                        yield put(actionPendingOrderUpdate(data));
                    }
                }

                if (item.type === DIALOG_TYPE_CONFIRM.delivered || item.type === DIALOG_TYPE_CONFIRM.reject) {
                    yield put(actionRemoveOrder([id]));
                    yield put(actionRemoveMarker([id]));
                }
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* consumerOrderAddSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(addConsumerOrder, objectKeysToUpperLowerCase(action.data, true));
        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(actionPendingOrderUpdate(data));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* consumerOrderCancelSaga(action) {
    yield* createSaga(function*() {
        const { id, notes, order } = action.data;
        const request = order.isAssistOrder ? deleteAssistanceOrder : cancelConsumerOrder;
        const requestParams = order.isAssistOrder ? [id] : [id, { Notes: notes }];
        const requestResult = yield call(request, ...requestParams);
        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(actionPendingOrderUpdate(data));
            } else {
                yield put(actionRemoveOrder([id]));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* pendingOrderGetSaga(action) {
    yield* createSaga(function*() {
        if (currentUserIsDepotRole()) {
            const requestResult = yield call(fetchConsumerOrders);
            const order = yield select(getOrder);
            const { enableSound: dataSound } = action.data

            const enableSound = dataSound === undefined ? true : dataSound
            const isNewOrderComes = !requestResult.data.every(v => order.data.activeOrders.some(data => data.sequence_id === v.sequence_id));

            if(isNewOrderComes && order.data.activeOrders.length > requestResult.data.length && enableSound) {
                AudioService.instance.playSound("newConsumerOrderCreatedFromLoop");
            }
            if (requestResultIsOk(requestResult)) {
                yield put(
                    actionPendingOrdersUpdate({ activeOrders: objectKeysToUpperLowerCase(requestResult.data || []) })
                );
            }
        } else {
            AudioService.instance.playSound('newOrderCreated');
            const adminOrders = yield select(getAdminOrders);
            if (adminOrders && adminOrders.search && adminOrders.search.status === ACCOUNT_ORDER_STATUS.pending) {
                yield put({ type: ADMIN_ORDER_GET, data: adminOrders.search });
            }
        }
    }, action.type);
}

function* getConsumerPreOrdersSaga(action) {
    yield* createSaga(function*() {
        if (currentUserIsDepotRole()) {
            const requestResult = yield call(fetchPendingPreOrders);
            if (requestResultIsOk(requestResult)) {
                yield put(
                    actionPendingOrdersUpdate({
                        pendingPreOrders: (objectKeysToUpperLowerCase(requestResult.data) || [])
                            .map(preorder => ({ ...preorder, groupId: DEFAULT_ORDERS_GROUPS.preOrders }))
                            ?.sort((a, b) => sortByDate(a, b, 'creation_datetime')),
                    })
                );
            }
        }
    }, action.type);
}

function* adminOrderGetSaga(action) {
    yield* createSaga(function*() {
        yield put(actionAdminOrdersLoading(true));
        const filter = yield select(state => state.order.data.filter);
        const search = action.data || filter;
        const orders = yield select(getAdminOrders);
        const order = action.data.rememberOrder;
        if(action.data.isInit && orders && orders.items && orders.items.length) {
            yield put(actionAdminOrdersLoading(false));
            return orders;
        }
        delete search.isInit;
        const requestResult = yield call(fetchAdminOrders, objectKeysToUpperLowerCase(search, true));
        if (requestResultIsOk(requestResult)) {
            const newOrders = (objectKeysToUpperLowerCase(requestResult.data) || [])?.sort((a, b) =>
                sortByDate(a, b, 'creation_datetime')
            );
            if(order) {
                const orderToUpdate = newOrders.find(o => o.id === order.id);
                if(orderToUpdate && order && order.businessInfo && orderToUpdate.business_id === order.businessInfo.id) {
                    orderToUpdate.businessInfo = order.businessInfo;
                }
                if(order && orderToUpdate && (order?.courierInfo?.courierId === orderToUpdate?.driver_id)) {
                    orderToUpdate.courierInfo = order.courierInfo;
                }
                if(order && orderToUpdate && order.cancelerInfo) {
                    orderToUpdate.cancelerInfo = order.cancelerInfo;
                }
                if(order && orderToUpdate && order.consumerInfo) {
                    orderToUpdate.consumerInfo = order.consumerInfo;
                }
            }
            yield put(
                actionAdminOrdersUpdate({
                    search,
                    items: newOrders
                })
            );
            yield put(actionAdminOrdersLoading(false));
            return yield select(getAdminOrders);
        }
        yield put(actionAdminOrdersLoading(false));
    }, action.type);
}

function* adminOrderSearchSaga(action) {
    yield* createSaga(function*() {
        if (action.data) {
            const requestResult = yield call(fetchAdminOrderById, action.data);
            if (requestResult && requestResult.status === 200 && requestResult.data) {
                if (requestResult.data) {
                    yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
                    yield put(actionAdminOrderGetOrderInfo(action.data));

                    const adminOrders = yield select(getAdminOrders);
                    return (adminOrders.items || []).find(f => f.id === action.data);
                }
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* adminOrderInfoSaga(action) {
    yield* createSaga(function*() {
        const id = action.data;

        if (id) {
            const adminOrders = yield select(getAdminOrders);
            let currentOrder = adminOrders.items && adminOrders.items.find(f => f.id === id);

            const requestCallObj = {
                courierInfo: call(fetchCourierInfo, id),
                businessInfo: call(getCallCenterMenu, currentOrder.business_id),
                cancelerInfo: call(fetchCancelerInfo, id)
            };
            if (currentOrder.customer_id) {
                requestCallObj.consumerInfo = call(fetchConsumerInfo, currentOrder.customer_id);
            }

            if (currentOrder) {
                const requestResult = yield all(requestCallObj);
                const { consumerInfo, courierInfo, businessInfo, cancelerInfo } = requestResult;
                currentOrder = {
                    ...currentOrder,
                    consumerInfo:
                        consumerInfo?.status === 200 &&
                        consumerInfo?.data &&
                        objectKeysToUpperLowerCase(consumerInfo?.data),
                    courierInfo:
                        courierInfo?.status === 200 && courierInfo?.data && objectKeysToUpperLowerCase(courierInfo?.data),
                    businessInfo:
                        businessInfo?.status === 200 &&
                        businessInfo?.data &&
                        objectKeysToUpperLowerCase(businessInfo?.data),
                    cancelerInfo: cancelerInfo.status === 200 && cancelerInfo?.data && objectKeysToUpperLowerCase(cancelerInfo?.data)

                };

                yield put(actionAdminOrderUpdate(currentOrder));
            }
        }
    }, action.type);
}

function* orderAdminInfoSaga(action) {
    yield* createSaga(function*() {
        const id = action.data;
        let requestResult = null;

        if (id) {
            const orders = yield select(getOrders);
            const adminOrders = yield select(getAdminOrders);
            const order = orders.find(f => f.id === id);
            let currentOrder = adminOrders.items && adminOrders.items.find(f => f.id === order.integrationId);

            if (!currentOrder && order.integrationId) {
                requestResult = yield call(
                    fetchAdminOrders,
                    objectKeysToUpperLowerCase({ search: order.integrationId }, true)
                );
                if (requestResult && requestResult.status === 200 && requestResult.data) {
                    currentOrder = objectKeysToUpperLowerCase(requestResult.data)[0];
                }
            }

            if (currentOrder) {
                requestResult = yield all({
                    consumerInfo: call(fetchConsumerInfo, currentOrder.customer_id),
                    courierInfo: call(fetchCourierInfo, order.integrationId),
                    businessInfo: call(fetchBusinessInfo, currentOrder.business_id),
                    cancelerInfo: call(fetchCancelerInfo, order.integrationId)
                });
                const { consumerInfo, courierInfo, businessInfo } = requestResult;
                currentOrder = {
                    ...currentOrder,
                    consumerInfo:
                        consumerInfo.status === 200 &&
                        consumerInfo.data &&
                        objectKeysToUpperLowerCase(consumerInfo.data),
                    courierInfo:
                        courierInfo.status === 200 && courierInfo.data && objectKeysToUpperLowerCase(courierInfo.data),
                    businessInfo:
                        businessInfo.status === 200 &&
                        businessInfo.data &&
                        objectKeysToUpperLowerCase(businessInfo.data),
                    cancelerInfo: cancelerInfo.status === 200 && cancelerInfo.data && objectKeysToUpperLowerCase(cancelerInfo.data)
                };
            }

            return currentOrder;
        }
    }, action.type);
}

function* getProductByIdBusiness(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchBusinessInfo, action.data);
        if (requestResultIsOk(requestResult)) {
            if(requestResult.data) {
                const data = objectKeysToUpperLowerCase(requestResult.data.categories || [])
                    .flatMap(category => category)
                    .flatMap(category => category.products);
                yield put(setProductAction(data));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* orderMenuGetSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(fetchOrderMenu, id);

        if (requestResult && requestResult.status === 200) {
            yield put(
                actionOrderMenuUpdate({
                    ...objectKeysToUpperLowerCase(requestResult.data),
                    orderId: id,
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getMergedBusinessMenuByIdSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getMergedBusinessMenuById, action.data.id);
        if (requestResult && requestResult.status === 200) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ ...restaurant, businessMenus: (restaurant.businessMenus || []).concat(requestResult.data) }));
        }
    }, action.type);
}

function* pendingOrderDataGetSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(fetchOrderMenu, id);

        if (requestResultIsOk(requestResult)) {
            const {businessMenus, consumerOrderMenu} = yield select(state => state.restaurant.data);
            if(requestResult.data.business_id && consumerOrderMenu &&
                !(businessMenus || []).some(m => m.id === requestResult.data.business_id)
                && consumerOrderMenu.id !== requestResult.data.business_id
            ) {
                yield put(createAction(GET_MERGED_BUSINESS_MENU_BY_ID, {id: requestResult.data.business_id}));
            }
            return objectKeysToUpperLowerCase(requestResult.data);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* pendingOrderUnAssociateOrderSaga(action) {
    yield* createSaga(function*() {
        const order = action.data.order;
        const courierId = order && (order.groupId || order.courierId || order.courier_id);
        const externalCycles = yield select(getExternalCycles);
        const externalCycle = order && externalCycles.find(f => f.id === courierId);
        const data = externalCycle
            ? { id: courierId, orderIds: [order.id] }
            : { orderId: order.id, groupId: DEFAULT_ORDERS_GROUPS.Unsorted };
        const actionName = externalCycle ? actionDisbandOrderFromExternalCycle : actionCourierToGroup;
        yield put(actionName(data));
    }, action.type);
}

function* pendingOrderCancelCycleSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const externalCycles = yield select(getExternalCycles);
        const externalCycle = externalCycles.find(f => f.id === id);

        if (externalCycle) {
            yield put(actionExternalCycleDisband({ id }));
        } else {
            const requestResult = yield call(cancelCycle, id);
            if (requestResultIsOk(requestResult)) {
                const groups = yield select(getGroups);
                const users = yield select(getUserModel);
        const group = groups.find(f => f.id === id);
        const user = users.find(f=> f.userId === id);

                if(user) {
                  yield put(actionCourierUpdate({
                    ...user,
                    courierState: COURIER_STATE.cancelShift,
                    cycle: { ...(user.cycle || {}), state: CYCLE_STATE.started }
                  }));
                }

                if (group) {
                    yield put(
                        actionCourierUpdate({
                            ...group,
                            courierState: COURIER_STATE.cancelShift,
                            cycle: group.cycle && { ...group.cycle, state: CYCLE_STATE.started },
                        })
                    );
                }

                yield put(
                    actionPendingOrdersUpdate({
                        activeOrders: objectKeysToUpperLowerCase(requestResult.data.Orders)
                    })
                );
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

function* orderNoteEditSaga(action) {
    yield* createSaga(function*() {
        const { id, value } = action.data;
        const requestResult = yield call(updateServiceNotes, id, value);
        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateOrderServiceNotes({ id, value }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* adminOrderReshipmentSaga(action) {
    yield* createSaga(function*() {
        const data = getReshipmentOrderData(action.data);
        const requestResult = yield call(reshipmentOrder, action.data.order.id, data);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* adminOrderRefundSaga(action) {
    yield* createSaga(function*() {
        const data = getInitModel(MODEL_TYPE.refundServerModel, action.data);
        const requestResult = yield call(refundOrder, action.data.id, data);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* adminOrderCaptureSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(setCaptureRemainder, action.data.id, action.data.body);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
        return;
    }, action.type);
}

function* getAdminOrderPendingCountSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getAdminOrderPengingCount);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderPendingCountUpdate(requestResult.data.Count));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
        return;
    }, action.type);
}

function* pushMessageSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(pushMessage, action.data);
        if (requestResult && requestResult.status === 200) {
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* adminOrderDeliveredSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deliveryAdminOrder, action.data.id);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* adminOrderCancelSaga(action) {
    yield* createSaga(function*() {
        const { id, notes } = action.data;
        const requestResult = yield call(cancelAdminOrder, id, { Notes: notes });
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(actionAdminOrderUpdate(objectKeysToUpperLowerCase(requestResult.data)));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* consumerOrderSearchSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(consumerOrderSearch, action.data);
        if (requestResultIsOk(requestResult)) {
      if(requestResult.data) {
            const data = objectKeysToUpperLowerCase (requestResult.data) ;
                yield put(actionConsumerOrdersSearch(data));
        yield put(actionCheckUsersColors(getDistinctUsersIdsFromData(data)));

        //Check users existed
        const users = yield select(getUserModel);
        const existedUsersIds = users.map(m=> m.userId);
        const neededUsersIds = data.reduce((a, v)=> {
          if(v.courier_id && !existedUsersIds.includes(v.courier_id) && !a.includes(v.courier_id)) {
            a.push(v.courier_id);
          }

          return a;
        }, []);
        yield put(actionLoadUsersByIds({ ids: neededUsersIds }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getMarketplaceSaga(action) {
    yield* createSaga(function*() {
        yield put(actionGetGiftCard());
        yield put(actionGetListPromotion());

        const requestResult = yield call(fetchMarketplaceMessage, action.data);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                yield put(setMarketplace(requestResult.data));

                const {id} = getHistoryLocationParams(window.location.search);
                if(id) {
                    const message = requestResult.data.find(m => m.id === id);
                    if(message && message.business_id) {
                        yield getProductByIdBusiness({data: message.business_id});
                    }
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getMarketplaceDataSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchMarketplaceMessage, action.data);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                yield put(setMarketplace(requestResult.data));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getPagesSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(fetchPages, action.data);
        if (requestResult && requestResult.status === 200) {
            if (requestResult.data) {
                yield put(setMarketplacePages(requestResult.data));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* groupReorderPusherSaga(action) {
    yield* createSaga(function*() {
        const { groupId, isLocked, optimalData, orderIds, routeEstimation } = objectKeysToUpperLowerCase(action.data);

        if (isNotDefaultOrderGroup(groupId)) {
            const groups = yield select(getGroups);
            const newGroup = getGroup(null, null, groupId);
            const currentGroup = groups.find(f => f.id === groupId);

            yield put(
                actionUpdateGroup({
                    ...newGroup,
                    color: (currentGroup && currentGroup.color) || newGroup.color,
                    isLocked,
                    optimalData,
                    routeEstimation,
                })
            );

            if (Array.isArray(orderIds) && orderIds.length) {
                const orders = yield select(getOrders);
                yield put(
                    actionUpdateOrders(
                        orders
                            .filter(f => orderIds.includes(f.id))
                            .map(order => ({ ...order, groupId, index: orderIds.indexOf(order.id) }))
                    )
                );
            }

            yield put(actionUpdateGroupOrdersMarkers({ groupId }));
        }
    }, action.type);
}

function* createNewGroupSaga(action) {
    yield* createSaga(function*() {
        const {orders, groups} = yield select(state => state.order.data);
        if(action.data.NewGroup) {
            const selectedOrders = orders.filter(o => action.data.OrderIds.includes(o.id));
            const uniqueControlCenters = [...new Set(selectedOrders.map(o => o.controlCenterId))];
            if(uniqueControlCenters.length !== 1) {
                return new ErrorData('Error');
            }
        } else if(action.data.TargetGroupId) {
            const newOrderInGroup = orders.find(o => action.data.OrderId === o.id);
            const group = groups.find(g => g.id === action.data.TargetGroupId);
            if(group.controlCenterId !== newOrderInGroup.controlCenterId) {
                return new ErrorData('Error');
            }
        }

        const requestResult = yield call(groupReorder, action.data);
        if (requestResultIsOk(requestResult)) {
            if (requestResult.data) {
                const { id, isLocked, optimalData, orders, routeEstimation, pickupETA, controlCenterId } = objectKeysToUpperLowerCase(
                    requestResult.data
                );

                yield put(actionUpdateOrders(orders.map((order, index) => ({ ...order, groupId: id, index }))));

                if (groups.find(f => f.id === id)) {
                    yield put(actionUpdateGroup({ id, isLocked, optimalData, routeEstimation, pickupETA, controlCenterId }));
                } else {
                    const newGroup = getGroup(null, null, id);
                    yield put(actionUpdateGroup({ ...newGroup, isLocked, optimalData, routeEstimation, pickupETA, controlCenterId }));
                }

                yield put(actionUpdateGroupOrdersMarkers({ groupId: id }));
                yield put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getMergedBusinessMenuSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getMergedBusinessMenu);

        if (requestResult && requestResult.status === 200) {
            yield put(actionRestaurantUpdate({ consumerOrderMenu: requestResult.data }));
        }
    }, action.type);
}

function* getSetupIntentSaga(action) {
    yield* createSaga(function* () {
        const territory = yield select(state => state.editCustomerOrder.territory);
        const requestResult = yield call(getOrderSetupIntent, territory);
        if (requestResult.status === 200) {
            yield put(setPaymentIntent(requestResult.data));
        }
    }, action.type);
}

function* stripeVerifyCardOrderSaga(action) {
    yield* createSaga(function* () {
        const territory = yield select(state => state.editCustomerOrder.territory);
        const requestResult = yield call(stripeVerifyCardOrder, {
            Territory: territory,
            Token: action.data.token,
            Expiry: action.data.expiry
        });
        return requestResult.data || {};
    }, action.type);
}

function* getOrderPaymentIntentSaga(action) {
    yield* createSaga(function*() {
        const { businessMenu } = yield select(getRestaurant);
        const {products, deliveryTime, address, coupon, payments, courierTip, deliveryType} = yield select(state => state.editCustomerOrder);
        const bonus = payments.find(p => p.paymethod_id === PAY_METHOD.bonus);
        const body = {
            business_id: businessMenu.id,
            delivery_datetime: deliveryTime,
            location: address?.location,
            products: products,
            coupon: (coupon || '').trim(),
            requested_bonus: bonus ? bonus.saleAmount || bonus.price : null,
            driver_tip: courierTip,
            delivery_type: deliveryType,
            payment_data: '',
        };

        const requestResult = yield call(getOrderPaymentIntent, body);
        if (requestResult.status === 200) {
            yield put(setPaymentIntent(requestResult.data));
        }
    }, action.type);
}

function* setCreatedOrdersSaga(action) {
    yield* createSaga(function*() {
        try {
            const { businessMenu, paymentsConfiguration } = yield select(getRestaurant);
            const { newOrder, orderSummary, products } = yield select(state => state.orderEdit.data);

            const body = createOrderServerModel({
                ...action.data,
                ...newOrder,
                ...orderSummary,
                products,
                deliveryStrategy: newOrder.targetTime ? DELIVERY_STRATEGY.concreteTime : DELIVERY_STRATEGY.asap,
                businessId: businessMenu.id,
                paymentsConfiguration: paymentsConfiguration,
            });

            const requestResult = yield call(setCreatedOrders, body);
            yield put(actionAdminOrderUpdate(requestResult.data));
            yield put(setAdminOrderSelectedOrderId(requestResult.data.id));
            if (requestResult.status !== 200) {
                return new ErrorData(getErrorMessage(requestResult) || 'Error');
            }
        } catch (error) {
            return new ErrorData(getErrorMessage(error) || 'Error');
        }
    }, action.type);
}

export default function*() {
    yield takeEvery(ORDERS_LOAD, ordersSaga);
    yield takeEvery(PUSHER_CYCLE_REORDER, reorderOrdersSaga);
    yield takeEvery(PUSHER_NEW_ORDER, newOrderSaga);
    yield takeEvery(PUSHER_ORDER_STATE_UPDATE, updateOrderStateSaga);
    yield takeEvery(PUSHER_UPDATE_ORDER, updateOrderDataSaga);
    yield takeEvery(PUSHER_MANUAL_ASSOCIATE_UPDATE, manualAssociateUpdateSaga);
    yield takeEvery(PERFORM_ORDER_ASSOCIATING, associateOrderSaga);
    yield takeEvery(CHANGE_ORDER_STATE, changeOrderStateSaga);
    yield takeEvery(CHANGE_ORDER_INDEX, reorderSaga);
    yield takeEvery(ASSOCIATE_ORDERS, associateOrdersSaga);
    yield takeEvery(PUSHER_MANUAL_UNASSOCIATE_UPDATE_LIST, manualUnassociateUpdateListSaga);
    yield takeEvery(UPDATE_GROUP_ORDERS_MARKERS, updateOrderMarkersSaga);
    yield takeEvery(ORDER_DELETE, deleteOrderSaga);
    yield takeEvery(ORDER_ASSISTANCE_DELETE, deleteAssistanceOrderSaga);
    yield takeEvery(SAVE_ORDER_TO_SERVER, saveOrderToServerSaga);
    yield takeEvery(APPROVE_ASSISTANCE_ORDER, approveAssistanceOrderSaga);
    yield takeEvery(GET_ORDER_RECEIPTS, getReceiptsSaga);
    yield takeEvery(GET_ADVANCED_INFO, getAdvancedInfoSaga);
    yield takeEvery(LOAD_HISTORY_ORDERS, loadHistoryOrdersSaga);
    yield takeEvery(GET_ORDERS_PROVIDER, getOrdersProviderSaga);
    yield takeEvery(RELOAD_ORDER, reloadOrderSaga);
    yield takeEvery(SET_ORDER_DELIVERED_BY, setOrderDeliveredBySaga);
    yield takeEvery(PENDING_ORDER_SAVE, pendingOrderSaveSaga);
    yield takeEvery(CONSUMER_ORDER_ADD, consumerOrderAddSaga);
    yield takeEvery(CONSUMER_ORDER_CANCEL, consumerOrderCancelSaga);
    yield takeEvery(PENDING_ORDER_GET, pendingOrderGetSaga);
    yield takeLatest(PENDING_ORDER_DATA_GET, pendingOrderDataGetSaga);
    yield takeEvery(PENDING_ORDER_UN_ASSOCIATE_ORDER, pendingOrderUnAssociateOrderSaga);
    yield takeEvery(PENDING_ORDER_CANCEL_CYCLE, pendingOrderCancelCycleSaga);
    yield takeEvery(CONSUMER_PRE_ORDERS_GET, getConsumerPreOrdersSaga);
    yield takeEvery(GET_MERGED_BUSINESS_MENU, getMergedBusinessMenuSaga);
    yield takeEvery(GET_MERGED_BUSINESS_MENU_BY_ID, getMergedBusinessMenuByIdSaga);
    yield takeEvery(ORDER_MENU_GET, orderMenuGetSaga);
    yield takeEvery(PUSHER_CONSUMER_ORDER_ARRIVED, pendingOrderGetSaga);
    yield takeLatest(ADMIN_ORDER_GET, adminOrderGetSaga);
    yield takeEvery(ADMIN_ORDER_GET_BY_ID, adminOrderSearchSaga);
    yield takeLatest(ADMIN_ORDER_GET_ORDER_INFO, adminOrderInfoSaga);
    yield takeEvery(ORDER_ADMIN_INFO_GET, orderAdminInfoSaga);
    yield takeEvery(ORDER_NOTE_EDIT, orderNoteEditSaga);
    yield takeEvery(ADMIN_ORDER_RESHIPMENT, adminOrderReshipmentSaga);
    yield takeEvery(ADMIN_ORDER_REFUND, adminOrderRefundSaga);
    yield takeEvery(ADMIN_ORDER_CAPTURE, adminOrderCaptureSaga);
    yield takeEvery(PUSH_MESSAGE, pushMessageSaga);
    yield takeEvery(ADMIN_ORDER_CANCEL, adminOrderCancelSaga);
    yield takeEvery(ADMIN_ORDER_DELIVERED, adminOrderDeliveredSaga);
    yield takeEvery(CONSUMER_ORDER_SEARCH_SAGA, consumerOrderSearchSaga);
    yield takeEvery(GET_PRODUCTS_BY_ID_BUSINESS, getProductByIdBusiness);
    yield takeEvery(MARKETPLACE_MESSAGE_GET, getMarketplaceSaga);
    yield takeEvery(MARKETPLACE_MESSAGE_DATA_GET, getMarketplaceDataSaga);
    yield takeEvery(PUSHER_GROUP_REORDER, groupReorderPusherSaga);
    yield takeEvery(NEW_GROUP_REORDER, createNewGroupSaga);
    yield takeEvery(MARKETPLACE_PAGES_GET, getPagesSaga);
    yield takeEvery(GET_ORDER_PAYMENT_INTENT, getOrderPaymentIntentSaga);
    yield takeEvery(GET_ORDER_SETUP_INTENT, getSetupIntentSaga);
    yield takeEvery(STRIPE_VERIFY_CARD_ORDER, stripeVerifyCardOrderSaga);
    yield takeEvery(SET_CREATED_ORDER, setCreatedOrdersSaga);
    yield takeEvery(GET_ADMIN_ORDER_PENDING_COUNT, getAdminOrderPendingCountSaga);
}
